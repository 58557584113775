import { useMemo } from 'react';
import { INIT_WALLET_TOKENS_LIMIT } from 'cb-wallet-data/stores/Collection/hooks/useRefreshCollectionDetails';
import { useTokenDetails } from 'cb-wallet-data/stores/Collection/hooks/useTokenDetails';
import { CollectionToken } from 'cb-wallet-data/stores/Collection/models/CollectionToken';
import { WalletCollectionToken } from 'cb-wallet-data/stores/Collection/models/WalletCollectionToken';
import { CachedMediaType, CollectibleLink } from 'cb-wallet-data/stores/Collection/types';
import { convertIpfsToHttps } from 'cb-wallet-data/stores/Collection/utils/convertIpfsToHttps';
import {
  BasicProfile,
  useBasicPublicProfile,
} from 'cb-wallet-data/stores/DecentralizedID/hooks/useBasicPublicProfiles';
import { useGetCollectionDetails } from 'cb-wallet-data/stores/Explore/hooks/useGetCollectionDetails';
import { useGetCollectionTokens } from 'cb-wallet-data/stores/Explore/hooks/useGetCollectionTokens';
import { useGetWalletCollectionTokens } from 'cb-wallet-data/stores/Explore/hooks/useGetWalletCollectionTokens';
import { useGetTrendingMintCollection } from '@cbhq/instant-api-hooks-creator-service/fetch';
import {
  V1Amount,
  V1ContractKind,
  V1Price,
  V1TakerEligibility,
} from '@cbhq/instant-api-hooks-creator-service/types';

import { useNetworkMetadata } from './useNetworkMetadata';

const DEFAULT_MAX_MINTS_PER_WALLET = 9_999;

const BASE = {
  wacNetworkName: 'networks/base-mainnet',
  chainId: '8453',
};

const descriptionOverrides = new Map<string, string>([
  ['0xtest:0'.toLocaleLowerCase(), 'test override'],
]);

type Params = {
  contractAddress: string;
  chainId?: string;
  tokenId?: string;
  walletAddress?: string;
};

export type NftData = {
  name: string | undefined;
  description: string | undefined;
  collection?: {
    name: string | undefined;
    description: string | undefined;
    cachedImage?: CachedMediaType;
    totalTokens?: string;
  };
  contractAddress: string | undefined;
  creatorAddress: string | undefined;
  creatorProfile?: BasicProfile;
  isMinting: boolean;
  floorPrice?: string;
  ethVolumeWeek?: string;

  cachedImage?: CachedMediaType;
  ownerCount?: string;
  cachedAnimation?: CachedMediaType;
  totalTokens?: string;
  verified?: boolean;
  ownerAddress?: string;
  ownerProfile?: BasicProfile;
  attributes?: {
    category: string;
    attribute: string;
  }[];
  isSpam?: boolean;
  claimedTokens: WalletCollectionToken[];
  collectionTokens: CollectionToken[];
  price?: V1Price;
  mintFee?: V1Amount;
  takerEligibility?: V1TakerEligibility;
  maxMintsPerWallet: number;
  network?: ReturnType<typeof useNetworkMetadata>;
  links?: CollectibleLink[];
  refetchToken: ReturnType<typeof useTokenDetails>['refetch'];
  galleryId?: string;
  isCreatedMint?: boolean; // true if the token was created in the dapp by users (tokenForge mint)
  maxSupply?: string;
};

/**
 * Fetches NFT data for a given contract address and optional token ID.
 * Abstracts away the following hooks:
 * - useTokenDetails
 * - useGetTrendingMintCollection
 * - useGetCollectionDetails
 * - useGetWalletCollectionTokens
 * - useGetCollectionTokens
 * - useBasicPublicProfile
 * - useNetworkMetadata
 * @returns NftData
 */
export function useNftData(params: Params): NftData | undefined {
  const { contractAddress, tokenId, walletAddress } = params;
  const network = useNetworkMetadata({ chainId: params.chainId ?? BASE.chainId });

  const tokenDetails = useTokenDetails({
    contractAddress,
    tokenId,
    chainId: network.chainId.toString(),
  });

  const trendingMint = useGetTrendingMintCollection({
    network: network.wacNetworkId,
    address: contractAddress,
    tokenId,
    takerAddress: walletAddress,
  });

  const { data: collectionDetails } = useGetCollectionDetails({
    contractAddress,
    chainId: network.chainId.toString(),
  });

  const { walletTokens: claimedTokens } = useGetWalletCollectionTokens({
    contractAddress,
    walletAddress,
    chainId: network.chainId.toString(),
    limit: INIT_WALLET_TOKENS_LIMIT,
  });

  const { tokens: collectionTokens } = useGetCollectionTokens({
    contractAddress,
    chainId: network.chainId.toString(),
  });

  const addresses: string[] = useMemo(
    () =>
      [trendingMint?.collection?.creatorAddress, tokenDetails.data?.ownerAddress].filter<string>(
        (addr): addr is string => addr !== undefined,
      ),
    [trendingMint, tokenDetails],
  );

  const profiles = useBasicPublicProfile({ addresses });

  return useMemo(() => {
    if (tokenId && tokenDetails.isLoading) {
      return;
    }

    const metadata: Partial<NftData> = {};

    // For 1155 or 721 OE, prefer the token name over the collection name
    // For 721, we don't have a token info, so we use the collection info
    if (trendingMint.collection?.kind === V1ContractKind.CONTRACTKINDERC1155 || tokenId) {
      // For 1155 or 721 OE
      metadata.collection = {
        name: trendingMint?.collection?.name ?? collectionDetails?.collectionName,
        description:
          trendingMint?.collection?.description ?? collectionDetails?.collectionDescription,
        cachedImage: collectionDetails?.cachedCollectionImage,
        totalTokens: collectionDetails?.totalTokens,
      };

      metadata.name =
        trendingMint?.collection?.tokenDetails?.name ??
        tokenDetails.data?.name ??
        metadata.collection.name;
      metadata.description =
        trendingMint?.collection?.tokenDetails?.description ??
        tokenDetails.data?.description ??
        metadata.collection.description;
      metadata.cachedImage =
        tokenDetails.data?.cachedImageUrl ?? collectionDetails?.cachedCollectionImage;

      metadata.links = tokenDetails.data?.marketplaceUrls;
      metadata.cachedAnimation = tokenDetails.data?.cachedAnimationUrl;
      metadata.totalTokens = tokenDetails.data?.tokenCount ?? collectionDetails?.totalTokens;

      // TODO: check against pricePerQuantity as well.
      // TODO: how do we handle multiple stages?
      // TODO: when we have a selector, multiply price by quantity.
      const nowInSeconds = new Date().getTime() / 1000;
      const currentStage = trendingMint.collection?.stages?.find(
        (stage) =>
          stage.tokenId === tokenId ||
          (typeof stage.endTime === 'undefined' && stage.stage === 'public-sale') ||
          (stage.endTime && Number(stage.endTime) > nowInSeconds && stage.stage === 'public-sale'),
      );
      metadata.price = currentStage?.price;

      metadata.maxMintsPerWallet = currentStage
        ? Number(currentStage?.maxMintsPerWallet ?? DEFAULT_MAX_MINTS_PER_WALLET)
        : 1;
    } else {
      // For 721
      metadata.name = trendingMint?.collection?.name ?? collectionDetails?.collectionName;
      metadata.description =
        trendingMint.collection?.description ?? collectionDetails?.collectionDescription;

      metadata.cachedImage = collectionDetails?.cachedCollectionImage;
      metadata.totalTokens = collectionDetails?.totalTokens;

      const currentStage = trendingMint.collection?.stages?.[0];
      metadata.price = currentStage?.price;
      metadata.maxMintsPerWallet = Number(currentStage?.maxMintsPerWallet ?? 1);
    }

    metadata.maxSupply = trendingMint.collection?.maxSupply;

    if (trendingMint.collection?.tokenforgeMint && trendingMint.collection.imageUrl) {
      metadata.cachedImage = {
        cachedPath: convertIpfsToHttps(trendingMint.collection.imageUrl),
        mimeType: 'image/jpeg',
      };
    }
    if (trendingMint.collection?.tokenforgeMint && trendingMint.collection.animationUrl) {
      metadata.cachedAnimation = {
        cachedPath: convertIpfsToHttps(trendingMint.collection.animationUrl),
        mimeType: 'video/mp4',
      };
    }

    metadata.ownerAddress = tokenDetails.data?.ownerAddress;
    if (metadata.ownerAddress) {
      metadata.ownerProfile = profiles?.[metadata.ownerAddress];
    }

    metadata.attributes = tokenDetails.data?.attributes?.map((attribute) => ({
      category: attribute.attributeName ?? '',
      attribute: attribute.attributeValue ?? '',
    }));

    const key = `${trendingMint?.collection?.address?.toLocaleLowerCase()}:${tokenId}`;
    if (metadata && descriptionOverrides.has(key)) {
      const override = descriptionOverrides.get(key);
      if (override?.length) {
        metadata.description = override;
      }
    }

    // If total token is empty default to 0, this is needed for TokenForge mints
    if (!metadata?.totalTokens || metadata.totalTokens === '') {
      metadata.totalTokens = '0';
    }

    const result: NftData = {
      name: metadata.name,
      description: metadata.description,
      collection: metadata.collection,
      cachedImage: metadata.cachedImage,
      totalTokens: metadata.totalTokens,
      cachedAnimation: metadata.cachedAnimation,
      ownerAddress: metadata.ownerAddress,
      ownerProfile: metadata.ownerProfile,
      attributes: metadata.attributes,
      price: metadata.price,
      maxMintsPerWallet: metadata.maxMintsPerWallet,
      links: metadata.links,
      network,

      isSpam: tokenDetails.data?.spam,
      ownerCount: tokenDetails?.data?.ownerCount ?? collectionDetails?.ownerCount,
      verified: collectionDetails?.verified,
      contractAddress: trendingMint.collection?.address,
      creatorAddress: trendingMint.collection?.creatorAddress,
      isMinting:
        !!trendingMint?.collection?.stages?.[0] && Boolean(trendingMint?.collection?.isMinting),
      floorPrice: collectionDetails?.floorPrices?.[0]?.floorPrice?.amount,
      ethVolumeWeek: collectionDetails?.ethVolumeWeek,
      claimedTokens,
      collectionTokens,
      mintFee: trendingMint.mintFee,
      takerEligibility: trendingMint.takerEligibility,
      refetchToken: tokenDetails.refetch,
      galleryId: trendingMint?.collection?.galleryId,
      isCreatedMint: trendingMint.collection?.tokenforgeMint,
      maxSupply: metadata.maxSupply,
    };

    if (result.creatorAddress) {
      result.creatorProfile = profiles?.[result.creatorAddress];
    }

    return result;
  }, [
    tokenDetails,
    trendingMint,
    tokenId,
    collectionDetails,
    claimedTokens,
    collectionTokens,
    profiles,
    network,
  ]);
}
