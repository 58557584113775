import { ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from 'wallet-cds-web/components/Button';
import { useToggler } from '@cbhq/cds-common';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { Box } from '@cbhq/cds-web/layout/Box';
import { TextBody, TextTitle2 } from '@cbhq/cds-web/typography';

import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { MintMedia } from ':dapp/pages/nft/mint/[contractAddress]/components/MintMedia';
import { useMintPageConfig } from ':dapp/pages/nft/mint/[contractAddress]/hooks/useMintPageConfig';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

import { Wrapper } from './Description';
import { MintShareModalInner } from './MintShareModal';

const messages = defineMessages({
  mintSuccessHeader: {
    defaultMessage: 'It’s yours',
    description: 'Header for the mint success modal',
  },
  mintSuccessBody: {
    // TODO: Utilize config service / CMS / BE to get confirmation message, or have a generic fallback
    defaultMessage:
      'Your mint is confirmed — you’re officially the new owner of {collectionName}. The NFT will show up in your wallet soon.',
    description: 'Body for the mint success modal',
  },
  exploreMore: {
    defaultMessage: 'Explore more',
    description: 'CTA for link to Explore more',
  },
});

type MintSuccessModalProps = {
  contractAddress: string;
  tokenId: string;
  chainId: string;
  collectionName?: string;
  handleSuccessClose?: () => void;
};

export function MintSuccessModal({
  contractAddress,
  tokenId = '1',
  chainId,
  collectionName,
  handleSuccessClose,
}: MintSuccessModalProps) {
  const [visible, { toggleOff }] = useToggler(true);
  const breakpoints = useBreakpoints();
  const modalWidth = breakpoints.isPhone ? undefined : 900;
  const boxWidth = breakpoints.isPhone ? undefined : '50%';
  const mediaWidth = '50%';
  const mediaSpacing = breakpoints.isPhone ? 0 : undefined;
  const mintPageConfig = useMintPageConfig();

  const { formatMessage } = useIntl();

  const primarySuccessButton = useMemo(() => {
    if (mintPageConfig?.successModal?.primarySuccessButton) {
      return mintPageConfig?.successModal?.primarySuccessButton;
    }
    return {
      text: formatMessage(messages.exploreMore),
      url: RoutesEnum.OCS,
    };
  }, [mintPageConfig, formatMessage]);

  const secondarySuccessButton = useMemo(() => {
    if (mintPageConfig?.successModal?.secondarySuccessButton) {
      return mintPageConfig?.successModal?.secondarySuccessButton;
    }
    return undefined;
  }, [mintPageConfig]);

  const collectionNameToDisplay = useMemo(() => {
    const slug = mintPageConfig?.slug || '';
    const formattedSlug = slug.charAt(0).toUpperCase() + slug.slice(1).toLowerCase();
    return `${collectionName ?? formattedSlug}`;
  }, [mintPageConfig, collectionName]);
  const handleOnClose = useCallback(() => {
    if (handleSuccessClose) {
      handleSuccessClose();
    }
    toggleOff();
  }, [handleSuccessClose, toggleOff]);
  return (
    <HomebaseModal
      title=" "
      visible={visible}
      onClose={handleOnClose}
      width={modalWidth}
      spacingHorizontal={breakpoints.isDesktop ? 4 : 0}
      contentSpacingTop={breakpoints.isPhone ? 0.5 : 3}
      contentSpacingBottom={breakpoints.isPhone ? 1 : 3}
      testID="mint-success-modal"
    >
      <ModalInner>
        <Box
          spacing={3}
          flexGrow={1}
          width={mediaWidth}
          spacingTop={mediaSpacing}
          spacingBottom={mediaSpacing}
          testID="MintSuccessModal-MetaData"
        >
          <MintMedia contractAddress={contractAddress} tokenId={tokenId} chainId={chainId} />
        </Box>
        <Box flexGrow={1} width={boxWidth} justifyContent="center" alignItems="center">
          <VStack spacing={3} spacingTop={2}>
            <TextTitle2 as="h2">
              {mintPageConfig?.successModal?.title || formatMessage(messages.mintSuccessHeader)}
            </TextTitle2>
            <TextBody color="foregroundMuted" as="p" spacingTop={2} spacingBottom={4}>
              <Wrapper>
                {mintPageConfig?.successModal?.description ||
                  formatMessage(messages.mintSuccessBody, {
                    collectionName: collectionNameToDisplay,
                  })}
              </Wrapper>
            </TextBody>
            <MintShareModalInner inline />
            <VStack gap={2} spacingTop={3}>
              {primarySuccessButton.text && (
                <Button
                  block
                  href={primarySuccessButton.url}
                  target="_blank"
                  testID="mint-success-primary-cta"
                >
                  {primarySuccessButton.text}
                </Button>
              )}
              {!!secondarySuccessButton && (
                <Button
                  block
                  variant="secondary"
                  href={secondarySuccessButton?.url}
                  target="_blank"
                  testID="mint-success-secondary-cta"
                >
                  {secondarySuccessButton?.text}
                </Button>
              )}
            </VStack>
          </VStack>
        </Box>
      </ModalInner>
    </HomebaseModal>
  );
}

type ModalInnerProps = { children: ReactNode };

function ModalInner({ children }: ModalInnerProps) {
  const breakpoints = useBreakpoints();

  if (breakpoints.isPhone) {
    return (
      <VStack testID="ModalInner-VStack" flexGrow={1} overflow="auto">
        {children}
      </VStack>
    );
  }

  return <HStack testID="ModalInner-HStack">{children}</HStack>;
}
