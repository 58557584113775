import { useMemo, useState } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { CAIP19Parser } from 'cb-wallet-data/utils/caip19AssetParser';
import { getJSON } from 'cb-wallet-http/fetchJSON';
import { V1GetMintSlugResponse } from '@cbhq/instant-api-hooks-creator-service/types';

type UseGetMintSlugProps = {
  slug: string;
};

export function useGetMintSlug(props: UseGetMintSlugProps) {
  const [loading, setLoading] = useState(true);

  let slug = props.slug;
  // Convert a CAIP19 URL to contract address
  const caip19Asset = CAIP19Parser.TryParse(props.slug);
  if (caip19Asset?.assetReference) {
    slug = caip19Asset.assetReference;
  }

  const { data } = useQuery({
    queryKey: ['getMintSlug', slug],
    queryFn: async () => {
      try {
        const response = await getJSON<V1GetMintSlugResponse>(
          'creators/getMintSlug',
          {
            slug,
          },
          {
            apiVersion: 3,
          },
        );
        return response;
      } finally {
        setLoading(false);
      }
    },
  });

  const memoizedSlug = useMemo(() => data?.slug ?? undefined, [data]);
  return {
    slug: memoizedSlug,
    loading,
  };
}
