import { CollectionInfoResponse } from 'cb-wallet-data/stores/Collection/types';

export class CollectionInfo {
  contractAddress: string;
  collectionName: string;
  collectionSymbol: string;
  contractType: string;
  chainId: string;
  ownerCount: string;
  tradeVolume: string;
  totalTokens: string;
  ethVolumeOneDay: string;
  ethVolumeOneDayTrend: string;
  ethVolumeOneWeekTrend: string;
  ethAveragePriceWeek: string;
  contractOwner: string;
  constructor(collectionInfoResponse: CollectionInfoResponse) {
    this.contractAddress = collectionInfoResponse.contractAddress;
    this.collectionName = collectionInfoResponse.collectionName;
    this.collectionSymbol = collectionInfoResponse.collectionSymbol;
    this.contractType = collectionInfoResponse.contractType;
    this.chainId = collectionInfoResponse.chainId;
    this.ownerCount = collectionInfoResponse.ownerCount;
    this.tradeVolume = collectionInfoResponse.tradeVolume;
    this.totalTokens = collectionInfoResponse.totalTokens;
    this.ethVolumeOneDay = collectionInfoResponse.ethVolumeOneDay;
    this.ethVolumeOneDayTrend = collectionInfoResponse.ethVolumeOneDayTrend;
    this.ethVolumeOneWeekTrend = collectionInfoResponse.ethVolumeOneWeekTrend;
    this.ethAveragePriceWeek = collectionInfoResponse.ethAveragePriceWeek;
    this.contractOwner = collectionInfoResponse.contractOwner;
  }
}
