import { useCallback, useEffect, useState } from 'react';
import { TxOrUserOpMetadataKey_txSource } from 'cb-wallet-data/chains/AccountBased/Ethereum/config';
import { generateUnsignedTransaction as generateUnsignedTransactionEthereum } from 'cb-wallet-data/chains/AccountBased/Ethereum/Transactions/generateUnsignedTransaction';
import { UnsignedTxOrUserOp } from 'cb-wallet-data/stores/Transactions/interfaces/UnsignedTxOrUserOp';
import {
  TxOrUserOpMetadataKey,
  TxOrUserOpMetadataValue,
} from 'cb-wallet-data/stores/Transactions/models/TxOrUserOpMetadataKey';
import { UnsignedTxOrUserOpResult } from 'cb-wallet-data/stores/Transactions/models/UnsignedTxOrUserOpResult';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';
import { strip0x } from 'cb-wallet-data/utils/String+Core';
import { getAddress } from 'viem';
import { setBaseUrl } from '@cbhq/instant-api-hooks-creator-service';
import { useMintToken } from '@cbhq/instant-api-hooks-creator-service/mutation';
import { V1MintTokenResponse } from '@cbhq/instant-api-hooks-creator-service/types';

const mintTxnSource = 'mint';

const ONE_MINUTE = 1000 * 60 * 1;
export const MINT_CALLDATA_DEFAULT_TIMOUT = 5 * ONE_MINUTE;

// TODO: Find a place higher up to put this. Otherwise we run into errors
function initializeApiHooks() {
  setBaseUrl('https://api.wallet.coinbase.com'); // PROD
}
initializeApiHooks();

export type MintProps = {
  contractAddress: string;
  wacNetworkId: string | undefined;
  feeWallet: Wallet | undefined;
  quantity: string;
  tokenId: string | undefined;
  timeout: number | undefined;
};

/**
 * Function that determines if we should reload calldata.  If the mintdata is > 5 mins old we should reload.
 * @param timeout : Timeout in MS
 * @param age : Age of the data
 * @param mintData : Data object
 */
function shouldReloadMintData(
  timeout: number,
  age: number | undefined,
  mintData: V1MintTokenResponse | undefined,
): boolean {
  // If any data is missing we need to refetch
  if (!age || !mintData) {
    return true;
  }

  // Check if the data is older than 5 minutes
  const diff = performance.now() - age;
  if (diff > timeout) {
    return true;
  }

  return false;
}

export type GetMintData = {
  data: () => Promise<V1MintTokenResponse | undefined>;
  error: Error | undefined;
  canMint: boolean;
  loading: boolean;
  txn: UnsignedTxOrUserOpResult<UnsignedTxOrUserOp> | undefined;
};

/**
 * Hook that is responsible for building Minting Calldata for a NFT mint.  We heavily cache this data to reduce
 * the hot-path duration when the user clicks the "mint" button.
 * @param contractAddress
 * @param wacNetworkId
 * @param feeWallet
 * @param quantity
 * @param tokenId
 * @param timeout
 */
export function useNftMintCalldata({
  contractAddress,
  wacNetworkId,
  feeWallet,
  quantity,
  tokenId,
  timeout = MINT_CALLDATA_DEFAULT_TIMOUT,
}: MintProps): GetMintData {
  const [error, setError] = useState<Error | undefined>();
  const [data, setData] = useState<V1MintTokenResponse | undefined>();
  const [mintDataAge, setMintDataAge] = useState<number | undefined>();
  const [txn, setTxn] = useState<UnsignedTxOrUserOpResult<UnsignedTxOrUserOp> | undefined>();
  const [loading, setLoading] = useState(true);
  const [canMint, setCanMint] = useState(false);

  const mintToken = useMintToken();

  // Function to fetch mint calldata from the Trending Mint API in creators service
  const getMintData = useCallback(
    async function getMintData() {
      try {
        setLoading(true);
        if (!feeWallet || !wacNetworkId || !quantity || !feeWallet) {
          return;
        }

        if (!shouldReloadMintData(timeout, mintDataAge, data)) {
          return;
        }

        const result = await mintToken({
          mintAddress: getAddress(contractAddress),
          network: wacNetworkId,
          quantity,
          takerAddress: feeWallet?.primaryAddress ? getAddress(feeWallet?.primaryAddress) : '',
          bypassSimulation: true,
          tokenId,
        });

        if (result instanceof Error) {
          return setError(result);
        }

        // Check if we returned calldata as that determines if the user can mint.
        if (result?.callData) {
          const metadata = new Map<TxOrUserOpMetadataKey, TxOrUserOpMetadataValue>([
            [TxOrUserOpMetadataKey_txSource, mintTxnSource],
          ]);
          // Generate a transaction primitively
          const unsignedTransaction = await generateUnsignedTransactionEthereum({
            wallet: feeWallet,
            recipientAddress: result.callData.to!,
            transactionAmount: {
              value: BigInt(result.callData.value!),
              kind: 'Amount',
            },
            metadata,
            txOrUserOpConfig: {
              txType: '1559',
              dataBuffer: Buffer.from(strip0x(result.callData.data!), 'hex'),
            },
          });
          setTxn(unsignedTransaction);
          setCanMint(true);
        } else {
          setError(new Error(`calldata null ${JSON.stringify(result?.callData)}`));
          setCanMint(false);
        }

        // Cache the mintdata for performance on SCW, otherwise this will result in popups being blocked in safari
        // ref: https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari/70463940#70463940
        setMintDataAge(performance.now());
        setData(result);
      } catch (err) {
        setError(err as Error);
        setCanMint(false);
      } finally {
        setLoading(false);
      }
    },
    [
      feeWallet,
      wacNetworkId,
      quantity,
      timeout,
      mintDataAge,
      data,
      mintToken,
      contractAddress,
      tokenId,
    ],
  );

  useEffect(
    function getMintDataOnLoad() {
      // Otherwise, get from mintToken response
      getMintData().catch(function handleErr(err) {
        setError(err);
      });
    },
    [getMintData, tokenId],
  );

  useEffect(
    // Function to reset calldata when core params change
    function resetData() {
      setData(undefined);
    },
    [quantity, feeWallet],
  );

  const mintData = useCallback(
    async function getMintDataCallback() {
      // Check if the mintdata needs to be refreshed
      if (shouldReloadMintData(timeout, mintDataAge, data)) {
        await getMintData();
      }
      return data;
    },
    [timeout, mintDataAge, data, getMintData],
  );

  return {
    data: mintData,
    error,
    canMint,
    txn,
    loading,
  };
}
