import { useCallback, useMemo, useRef, useState } from 'react';
import { getCloudinaryMediaUrl } from 'cb-wallet-data/stores/Collection/utils/getCloudinaryMediaUrl';
import { useMediaInfo } from 'wallet-cds-web/components/CollectibleMedia/hooks/useMediaInfo';
import { Icon } from '@cbhq/cds-web/icons/Icon';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media';

import { useNftData } from ':dapp/hooks/useNftData';
import { useDisplayNftCollectible } from ':dapp/pages/assets/nft/[address]/hooks/useDisplayNftCollectible';

const DEFAULT_IMAGE_HEIGHT = '375px';

type MintMediaProps = {
  contractAddress: string;
  tokenId?: string;
  chainId: string;
  width?: string | number;
  maxWidth?: string | number;
  // If false (default), video will never show controls. If true, video will show controls on hover/tap.
  showVideoControls?: boolean;
};

export function MintMedia({
  contractAddress,
  tokenId = '1',
  chainId,
  maxWidth,
  width = '100%',
  showVideoControls = false,
}: MintMediaProps) {
  const startTime = useRef<number>(Date.now());
  const { token, collection } = useDisplayNftCollectible({ contractAddress, tokenId, chainId });
  const [shouldShowControls, setShouldShowControls] = useState(false);

  const handleVideoInteracted = useCallback(() => {
    if (showVideoControls && !shouldShowControls) {
      setShouldShowControls(true);
    }
  }, [showVideoControls, shouldShowControls]);

  const nftData = useNftData({ contractAddress, tokenId, chainId });

  const [mediaInfo] = useMediaInfo({
    cachedImageUrl:
      nftData?.cachedImage ?? token.cachedTokenImage ?? collection?.cachedCollectionImage,
    cachedAnimationUrl: nftData?.cachedAnimation ?? token.cachedTokenAnimation,
    contractAddress,
    chainId,
    tokenId,
    startTime: startTime.current,
  });

  const isVideo = mediaInfo.mediaType?.startsWith('video');
  const isAudio = mediaInfo.mediaType?.startsWith('audio');
  const isImage = mediaInfo.mediaType?.startsWith('image');
  const isSvg = mediaInfo.mediaType?.startsWith('image/svg+xml');

  const source = useMemo(() => {
    const mediaSource = mediaInfo.source;
    // if mediaSource is coming from cache it's already a cloudinary url.
    if (mediaInfo.fromCache) return mediaSource;
    if (isVideo && mediaSource) {
      return getCloudinaryMediaUrl({
        media: mediaSource,
        type: 'video',
        options: { quality: 'max' },
      });
    }
    if (isImage && mediaSource) {
      return getCloudinaryMediaUrl({
        media: mediaSource,
        type: 'image',
        options: { quality: 'max' },
      });
    }
    return null;
  }, [mediaInfo, isVideo, isImage]);

  const validSource = source && source.startsWith('https://res.cloudinary.com/');

  if (isSvg) {
    return null;
  }

  let mediaElement = null;

  if (isVideo && validSource) {
    mediaElement = (
      <video
        loop
        muted
        autoPlay
        preload="auto"
        playsInline
        draggable={false}
        tabIndex={-1}
        src={source}
        data-testid="video"
        width="100%"
        controls={shouldShowControls}
        onClick={handleVideoInteracted}
        onMouseEnter={handleVideoInteracted}
      />
    );
  } else if (isAudio) {
    mediaElement = (
      <Box
        width="100%"
        height={DEFAULT_IMAGE_HEIGHT}
        background="backgroundAlternate"
        overflow="hidden"
      >
        <VStack height="100%" width="100%" justifyContent="center" alignItems="center">
          <Icon size="l" name="nft" color="foregroundMuted" testID="nftIcon" />
        </VStack>
      </Box>
    );
  } else {
    mediaElement = (
      <RemoteImage
        width="100%"
        height="100%"
        testID="mint-media-image"
        source={validSource ? source : undefined}
        alt={token.name}
      />
    );
  }

  return (
    <VStack
      borderRadius="roundedLarge"
      overflow="hidden"
      maxWidth={maxWidth}
      width={width}
      height="max-content"
    >
      {mediaElement}
    </VStack>
  );
}
