import { useCallback, useEffect, useState } from 'react';
import {
  MINT_CALLDATA_DEFAULT_TIMOUT,
  useNftMintCalldata,
} from 'cb-wallet-data/hooks/Mint/useNftMintCalldata';
import { SignedTx } from 'cb-wallet-data/stores/Transactions/interfaces/SignedTx';
import { signAndSubmitTransaction } from 'cb-wallet-data/stores/Transactions/methods/signAndSubmitTransaction';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

export type MintProps = {
  contractAddress: string;
  wacNetworkId: string | undefined;
  feeWallet: Wallet | undefined;
  quantity: string;
  tokenId: string | undefined;
  timeout?: number | undefined;
  walletProviderName?: string;
  source: string;
  skipPrepare?: boolean;
};

export type MintingMetrics = {
  mintingDataloadTimeMs: number | undefined;
  signatureTimeMs: number | undefined;
};

export type MintData = {
  canMint: boolean;
  mint: () => Promise<SignedTx | undefined>;
  isLoading: boolean;
  isMinting: boolean;
  error: Error | undefined;
  mintMetrics: MintingMetrics | undefined;
};

export function useNftMint({
  contractAddress,
  wacNetworkId,
  feeWallet,
  quantity,
  tokenId,
  timeout = MINT_CALLDATA_DEFAULT_TIMOUT,
  source,
  walletProviderName,
  skipPrepare = true,
}: MintProps): MintData {
  const [isLoading, setIsLoading] = useState(true);
  const [isMinting, setIsMinting] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [mintMetrics, setMintMetrics] = useState<MintingMetrics | undefined>();

  const {
    canMint,
    data: getMintData,
    error: mintCalldataError,
    txn: mintTxn,
    loading: mintDataLoading,
  } = useNftMintCalldata({ contractAddress, wacNetworkId, feeWallet, quantity, tokenId, timeout });

  // Loading State
  useEffect(
    function setLoadingState() {
      setIsLoading(mintDataLoading);
    },
    [mintDataLoading],
  );

  // Error State
  useEffect(
    function setErrorState() {
      if (mintCalldataError) {
        setError(mintCalldataError);
      }
    },
    [mintCalldataError],
  );

  const mint = useCallback(
    async function mintTokenAsync() {
      const mintMetricsCapture: MintingMetrics = {
        mintingDataloadTimeMs: undefined,
        signatureTimeMs: undefined,
      };

      const mintingStart = performance.now();
      // Since we already had taker eligibility, we hadn't loaded call data yet. For example, on mint feed
      setIsMinting(true);

      try {
        const mintData = await getMintData();

        // Error States
        if (!mintData?.callData) {
          setError(new Error('Unable to mint, invalid calldata'));
          setIsMinting(false);
          return;
        }
        if (!feeWallet) {
          setIsMinting(false);
          setError(new Error('Unable to mint, invalid wallet'));
          return;
        }
        if (!mintTxn) {
          setError(new Error('Unable to mint, invalid txn data'));
          setIsMinting(false);
          return;
        }

        // Reset error if we are in a valid state
        setError(undefined);

        mintMetricsCapture.mintingDataloadTimeMs = performance.now() - mintingStart; // emit total time to load mint data

        const transaction = await signAndSubmitTransaction({
          unsignedTxOrUserOp: mintTxn.transaction!,
          wallet: feeWallet,
          source,
          skipPrepare,
          onSubmit: () => {},
          txSubmissionType: 'original',
          walletProviderName,
        });

        mintMetricsCapture.signatureTimeMs = performance.now() - mintingStart; // emit time to sign + submit
        setMintMetrics(mintMetricsCapture);
        return transaction;
      } catch (err) {
        setError(err as Error);
        throw err;
      } finally {
        setIsMinting(false);
      }
    },
    [feeWallet, getMintData, mintTxn, skipPrepare, source, walletProviderName],
  );

  return {
    mint,
    canMint,
    isLoading,
    isMinting,
    error,
    mintMetrics,
  };
}
