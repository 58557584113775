import { useRouter } from 'next/router';

import { useGetMintSlug } from ':dapp/pages/nft/mint/[contractAddress]/hooks/useGetMintSlug';
import { getMintPageConfigBySlugOrContractAddress } from ':dapp/utils/mintPageConstants';

export function useMintPageConfig() {
  const { query } = useRouter();
  const { contractAddress } = query;

  const mintSlugResponse = useGetMintSlug({ slug: (contractAddress as string) || '' });
  const mintSlug = mintSlugResponse?.slug;
  const slugOrAddress = Array.isArray(contractAddress) ? contractAddress[0] : contractAddress;

  return getMintPageConfigBySlugOrContractAddress(slugOrAddress, mintSlug) ?? null;
}
