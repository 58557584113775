/* istanbul ignore file */
import { ReactNode, useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useToggler } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { Modal, ModalBody } from '@cbhq/cds-web/overlays';
import { Pressable } from '@cbhq/cds-web/system';
import { TextLegal, TextTitle2 } from '@cbhq/cds-web/typography';

import { useCopyToClipboard } from ':dapp/hooks/useCopyToClipboard';
import {
  CopyIcon,
  FarcasterSocialIcon,
  LensSocialIcon,
  XSocialIcon,
} from ':dapp/pages/nft/components/SocialIcons';
import { generateShareUrl } from ':dapp/utils/generateShareUrl';

const messages = defineMessages({
  mintShareHeader: {
    defaultMessage: 'Share this Mint',
    description: 'Header for the mint share modal',
  },
});

export function MintShareModal() {
  const [visible, { toggleOff, toggleOn }] = useToggler(false);
  const { isPhone } = useBreakpoints();

  const { formatMessage } = useIntl();

  return (
    <>
      <IconButton
        name="share"
        accessibilityLabel={formatMessage(messages.mintShareHeader)}
        variant="secondary"
        onPress={toggleOn}
        compact
      />
      <Modal
        visible={visible}
        onRequestClose={toggleOff}
        hideCloseButton={false}
        width={isPhone ? undefined : 450}
      >
        <ModalBody>
          <MintShareModalInner toggleOff={toggleOff} />
        </ModalBody>
      </Modal>
    </>
  );
}

type MintShareModalInnerProps = { toggleOff?: () => void; inline?: boolean };

export function MintShareModalInner({ toggleOff, inline = false }: MintShareModalInnerProps) {
  const { handleCopy } = useCopyToClipboard();
  const { isPhone } = useBreakpoints();

  const { formatMessage } = useIntl();

  const onPressCopy = useCallback(() => {
    handleCopy(window.location.href);
  }, [handleCopy]);

  const buttonStyle = useMemo(() => {
    if (isPhone) return { display: 'inline-block', float: 'right' };

    return { position: 'absolute', right: 35, top: 35 };
  }, [isPhone]);

  return (
    <VStack spacing={isPhone ? 0 : 5} spacingVertical={isPhone || inline ? 0 : 5}>
      {toggleOff && (
        <TextTitle2 as="h2" spacingBottom={3}>
          {/* @ts-expect-error */}
          <div style={buttonStyle}>
            <IconButton name="close" onClick={toggleOff} testID="MintSuccessModal-Close" />
          </div>
          {formatMessage(messages.mintShareHeader)}
        </TextTitle2>
      )}

      <HStack gap={3} justifyContent={isPhone ? 'space-between' : undefined}>
        <MintShareModalIcon type="x">
          <XSocialIcon color="#fff" />
        </MintShareModalIcon>
        <MintShareModalIcon type="farcaster">
          <FarcasterSocialIcon color="#fff" />
        </MintShareModalIcon>
        <MintShareModalIcon type="lens">
          <LensSocialIcon />
        </MintShareModalIcon>
        {/* <MintShareModalIcon type="discord">
      <DiscordSocialIcon />
    </MintShareModalIcon> */}
        <MintShareModalIcon type="copy" onPress={onPressCopy}>
          <CopyIcon />
        </MintShareModalIcon>
      </HStack>
    </VStack>
  );
}

export type SocialShareType = 'x' | 'farcaster' | 'lens' | 'discord' | 'copy';

type MintShareModalIconProps = {
  onPress?: () => void;
  children: NonNullable<ReactNode>;
  color?: string;
  url?: string;
  type: SocialShareType;
};

function MintShareModalIcon({ onPress, children, url, type }: MintShareModalIconProps) {
  const openShareUrl = useCallback(() => {
    const urlToShare = url || window.location.href;

    window.open(generateShareUrl(type, urlToShare), 'blank');
  }, [type, url]);

  const style = useMemo(() => {
    switch (type) {
      case 'x':
        return { backgroundColor: '#000' };
      case 'farcaster':
        return { backgroundColor: '#8866CD' };
      case 'lens':
        return { backgroundColor: '#272E29' };
      case 'discord':
        return { backgroundColor: '#5065E4' };
      case 'copy':
        return { backgroundColor: '#FFFFFF' };

      default:
        return undefined;
    }
  }, [type]);

  return (
    <Box>
      <VStack alignItems="center">
        <Pressable
          background="backgroundAlternate"
          style={style}
          onPress={onPress || openShareUrl}
          as="button"
          borderWidth="button"
          borderRadius="roundedFull"
          width={52}
          height={52}
        >
          {children}
        </Pressable>
        <TextLegal spacingTop={1} as="p">
          {type.charAt(0).toUpperCase() + type.slice(1)}
        </TextLegal>
      </VStack>
    </Box>
  );
}
