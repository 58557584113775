import { useInfiniteQuery } from '@tanstack/react-query';
import {
  FETCH_WALLET_COLLECTION_TOKENS,
  getWalletCollectionTokens,
} from 'cb-wallet-data/stores/Collection/api';
import { WalletCollectionTokenResponse } from 'cb-wallet-data/stores/Collection/types';

export type WalletCollectionTokensParams = {
  contractAddress: string;
  walletAddress?: string;
  chainId: string;
  page?: string;
  limit?: number;
};

export function useWalletCollectionTokens({
  contractAddress,
  chainId,
  walletAddress,
  page = '',
  limit = 18,
}: WalletCollectionTokensParams) {
  return useInfiniteQuery(
    [FETCH_WALLET_COLLECTION_TOKENS, contractAddress, walletAddress, limit, chainId],
    async ({ pageParam = page }) => {
      return getWalletCollectionTokens(
        chainId,
        contractAddress,
        pageParam || '',
        `${limit}`,
        walletAddress,
      );
    },
    {
      keepPreviousData: true,

      // FIXME: All functions in cb-wallet-data should be named so we can view them in profiles
      // eslint-disable-next-line wallet/no-anonymous-params
      getNextPageParam: (lastPage: {
        tokenList: WalletCollectionTokenResponse[];
        nextPage: string;
      }) => {
        if (!lastPage?.nextPage) return false;
        return lastPage.nextPage;
      },
    },
  );
}
