import { useCallback } from 'react';
import { useWalletGroups } from 'cb-wallet-data/stores/WalletGroups/hooks/useWalletGroups';
import { useWalletsByWalletGroup } from 'cb-wallet-data/stores/Wallets/hooks/useWalletsByWalletGroup';
import { Wallet } from 'cb-wallet-data/stores/Wallets/models/Wallet';

export function useGetWalletsForAccount() {
  const walletGroups = useWalletGroups();
  const walletsByWalletGroup = useWalletsByWalletGroup();

  return useCallback(
    (accountId: string): Wallet[] => {
      const walletgroup = walletGroups.find((wg) => wg.accountId === accountId);
      return walletgroup && walletsByWalletGroup[walletgroup.id]
        ? walletsByWalletGroup[walletgroup.id]
        : [];
    },
    [walletGroups, walletsByWalletGroup],
  );
}
