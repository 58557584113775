import { useMemo } from 'react';
import {
  useWalletCollectionTokens,
  WalletCollectionTokensParams,
} from 'cb-wallet-data/stores/Collection/hooks/useWalletCollectionTokens';
import { WalletCollectionToken } from 'cb-wallet-data/stores/Collection/models/WalletCollectionToken';
import { WalletCollectionTokenResponse } from 'cb-wallet-data/stores/Collection/types';

export function useGetWalletCollectionTokens({
  contractAddress,
  walletAddress,
  chainId,
  page,
  limit,
}: WalletCollectionTokensParams) {
  const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useWalletCollectionTokens({
      contractAddress,
      walletAddress,
      chainId,
      page,
      limit,
    });
  const walletTokens: WalletCollectionToken[] = useMemo(
    () =>
      (data?.pages ?? [])
        .flatMap((item: { tokenList: WalletCollectionTokenResponse[] }) => item?.tokenList ?? [])
        .map((token: WalletCollectionTokenResponse) => new WalletCollectionToken(token)),
    [data?.pages],
  );

  return useMemo(
    () => ({
      walletTokens,
      isLoading,
      isFetching,
      isFetchingNextPage,
      fetchNextPage,
      hasNextPage,
    }),
    [isLoading, isFetchingNextPage, fetchNextPage, walletTokens, isFetching, hasNextPage],
  );
}
