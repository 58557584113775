import { useCallback } from 'react';
import { useCollectionListings } from 'cb-wallet-data/stores/Collection/hooks/useCollectionListings';
import { useWalletCollectionTokens } from 'cb-wallet-data/stores/Collection/hooks/useWalletCollectionTokens';
import { useCollectionDetails } from 'cb-wallet-data/stores/Explore/api/useCollectionDetails';

type RefreshCollectionDetailsParams = {
  contractAddress: string;
  chainId: string;
  walletAddress: string;
};

export const INIT_LISTINGS_LIMIT = 3;
export const INIT_WALLET_TOKENS_LIMIT = 4;
export function useRefreshCollectionDetails({
  contractAddress,
  chainId,
  walletAddress,
}: RefreshCollectionDetailsParams) {
  const { refetch: refetchCollectionDetails } = useCollectionDetails({ contractAddress, chainId });
  const { refetch: refetchListings } = useCollectionListings({
    contractAddress,
    chainId,
    limit: INIT_LISTINGS_LIMIT,
  });
  const { refetch: refetchWalletCollectionTokens } = useWalletCollectionTokens({
    contractAddress,
    chainId,
    walletAddress,
    limit: INIT_WALLET_TOKENS_LIMIT,
  });
  return useCallback(
    function refreshCollectionDetails() {
      refetchCollectionDetails();
      refetchListings();
      refetchWalletCollectionTokens();
    },
    [refetchCollectionDetails, refetchListings, refetchWalletCollectionTokens],
  );
}
