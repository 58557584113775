import { ETHEREUM_PREFIX } from 'cb-wallet-data/chains/AccountBased/Ethereum/constants';
import { SOLANA_PREFIX } from 'cb-wallet-data/chains/AccountBased/Solana/utils/chain';
import { useIsSolanaCollection } from 'cb-wallet-data/hooks/Collections/useIsSolanaCollection';
import { Network } from 'cb-wallet-data/stores/Networks/models/Network';

type UseNetworkMetadataProps = { chainId: string; tokenType?: string };

export function useNetworkMetadata({ chainId, tokenType }: UseNetworkMetadataProps) {
  const isSolanaCollection = useIsSolanaCollection({ chainId, tokenType });

  const chainPrefix = isSolanaCollection ? SOLANA_PREFIX : ETHEREUM_PREFIX;
  const network = Network.fromChainId({ chainId: BigInt(chainId), chainPrefix });

  return {
    name: network.asChain()?.displayName,
    image: network.asChain()?.chainImageUrl,
    chainId: network.asChain()?.chainId ?? Number(chainId),
    wacNetworkId: network.asChain()?.wacNetworkId,
  };
}
