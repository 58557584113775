import { useMemo } from 'react';
import {
  CollectionTokensParams,
  useCollectionTokens,
} from 'cb-wallet-data/stores/Collection/hooks/useCollectionTokens';
import { CollectionInfo } from 'cb-wallet-data/stores/Collection/models/CollectionInfo';
import { CollectionToken } from 'cb-wallet-data/stores/Collection/models/CollectionToken';
import { Token } from 'cb-wallet-data/stores/Collection/types';

export function useGetCollectionTokens({ contractAddress, chainId }: CollectionTokensParams) {
  const { data, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useCollectionTokens({
      contractAddress,
      chainId,
    });

  const collectionInfo: CollectionInfo | undefined = useMemo(
    () => data?.pages?.[0]?.collectionInfo && new CollectionInfo(data?.pages[0].collectionInfo),
    [data?.pages],
  );

  const tokens: CollectionToken[] = useMemo(
    () =>
      (data?.pages ?? [])
        .flatMap((item: { tokens: { tokenList?: Token[] } }) => item.tokens.tokenList || [])
        .map((token: Token) => new CollectionToken(token)),
    [data?.pages],
  );

  return useMemo(
    () => ({
      collectionInfo,
      isLoading,
      isFetching,
      isFetchingNextPage,
      hasNextPage,
      fetchNextPage,
      tokens,
    }),
    [collectionInfo, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage, tokens, isFetching],
  );
}
