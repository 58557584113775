import {
  CachedMediaType,
  WalletCollectionTokenResponse,
} from 'cb-wallet-data/stores/Collection/types';

export class WalletCollectionToken {
  cachedImageUrl: CachedMediaType;
  contractAddress: string;
  description: string;
  name: string;
  tokenId: string;
  hasUnretrievableMetadata?: boolean;
  constructor(token: WalletCollectionTokenResponse) {
    this.cachedImageUrl = token.cachedImageUrl;
    this.contractAddress = token.contractAddress;
    this.description = token.description;
    this.name = token.name;
    this.tokenId = token.tokenId;
    this.hasUnretrievableMetadata = token.hasUnretrievableMetadata;
  }
}
