import { useInfiniteQuery } from '@tanstack/react-query';
import { FETCH_COLLECTION_TOKENS, getCollectionTokens } from 'cb-wallet-data/stores/Collection/api';

export const PAGE_SIZE = 24;
export type CollectionTokensParams = {
  contractAddress: string;
  chainId: string;
};

export function useCollectionTokens({ contractAddress, chainId }: CollectionTokensParams) {
  return useInfiniteQuery(
    [FETCH_COLLECTION_TOKENS, contractAddress, chainId],
    async ({ pageParam: nextPageCursor }) => {
      return getCollectionTokens(chainId, contractAddress, nextPageCursor, `${PAGE_SIZE}`);
    },

    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPageCursor;
      },
      cacheTime: 0,
    },
  );
}
